import React from 'react';
import Seo from '../components/layout/Seo'
import Layout from '../components/layout/Layout';
import Section from '../components/layout/Section'
import PageHeader from '../components/PageHeader'
import InstagramFeed  from 'react-ig-feed'
import 'react-ig-feed/dist/index.css'
import { StaticImage } from 'gatsby-plugin-image'
import Btn, { BtnGroup } from '../components/Btn';
import Fade from 'react-reveal/Fade';
import FadeBlock from '../components/FadeBlock';
// import SplitBackground from '../svg/SplitBG'

const AboutPage = () => {


  return (

    <Layout>
      <Seo title="About" description={`Known for his work with the Montreal neo-soul collective Busty and the Bass, Höhn is a new solo project from Calgary-born pianist and composer Eric Haynes.`} />

      <Section sectionName="About Intro">

    <div className={`flex flex-row md:flex-nowrap flex-wrap`}>
    <div className="md:flex-1/2 md:w-1/2 w-full md:pr-10 lg:pr-24 xl:pr-32 md:order-1 order-2 md:self-center">
      <FadeBlock direction={`left`}>
          <PageHeader pageTitle="About" align="left" size="text-4xl md:text-5xl md:mt-0 mt-7" />
          <p className="-mt-8">
          Hypnotic and electronic—would you believe it was made from the Earth? Vast wilderness and lakewater, scooped up and sculpted into one sonic fixation: You’re changed by the landscape, so you change it back. 
          </p>
          <p>
          From the tiniest sound makers, flies and mosquitos, to the effervescent flowing streams that lap and lap, <em>Höhn</em> is a 7-track album made entirely out of foraged field recordings. After travelling and camping through four remote locations in Alberta and Quebec, Eric Haynes turned raw sound into explosive composition.
          </p>
          <p>
          Rippling streams, bugs, distant fireworks, tossed firewood, waves crashing, birds calling, kids yapping, all redefined and stretched across the keyboard, making their own scales for synths or warping to fill roles like kick drums and hi-hats. 
          </p>
          <p>
          Using a few penned adjectives and sketched images to guide him, Eric ensured each song in <em>Höhn</em> remained entirely composed of its own location’s sounds — making each sonic atmosphere a unique artifact. 
          </p>
          <div className="mt-10 hidden">
            <h4 className="uppercase tracking-wide text-lg font-normal mb-4">Hohn on Instagram</h4>
            <InstagramFeed token="IGQVJVNERXUm9jOVd5OEotS042Y2tQT0FrUFY4V3JUZA1ljR05pYjlIV1BfaDZAYZAnlkX1VLWEYwMEtpaTFGM2wzVjVDalVjaG82dTR4OWh0STktOWV2R1F2ZA0xyZAFNvN2lzWjRUQzFpSkY1NldIX3JpawZDZD"  counter="12"/>
          </div>
      </FadeBlock>
		</div>
    <div className="md:flex-1/2 md:w-1/2 w-full md:order-2 order-1">
      <Fade right duration={500} distance={`70px`}>
			<StaticImage
	            src="../images/press/HOHN-AForgeot-PressPhoto-FINAL-46.jpg"
	            quality={75}
              placeholder="blurred"
	            formats={["AUTO", "WEBP", "AVIF"]}
	            alt="eric haynes"
	            className="shadow-lg"
				      style={{backgroundColor: 'var(--color-true-green)'}}
              imgClassName="mix-blend-multiply"
              aspectRatio={0.9}
	          />
        </Fade>
		</div>
    </div>
    <div className={`flex flex-row md:flex-nowrap flex-wrap pt-10 lg:pt-24 xl:pt-32 md:space-y-0 space-y-10`}>
    <div className="md:flex-1/2 md:w-1/2 w-full">
      <Fade left duration={500} distance={`70px`}>
			  <StaticImage
	            src="../images/press/HOHN-AForgeot-PressPhoto-FINAL-24.jpg"
	            quality={75}
              placeholder="blurred"
	            formats={["AUTO", "WEBP", "AVIF"]}
	            alt="eric haynes"
	            className="shadow-lg"
				      style={{backgroundColor: 'var(--color-true-green)'}}
              imgClassName="mix-blend-multiply"
              aspectRatio={0.9}
              objectPosition={`50% 100%`}
	          />
        </Fade>
		</div>
    <div className="md:flex-1/2 md:w-1/2 w-full md:pl-10 lg:pl-24 xl:pl-32 md:self-center">
      <FadeBlock direction={`right`}>
          <p>
          Braiding the natural with his own experience and reflections of nostalgia, he inserts his emotions and fuses with the summer scape —  preserving the memory without adding anything that wasn’t once there. Ambient and lucid, an ethereal trance, you plug in, unclench, and submit to becoming unknown in the wild.
          </p>
          <p>
          <em>Höhn</em> lets the environment talk, creating a greater message without a lyrical angle. Ambient and lucid, an ethereal trance, you plug in, unclench, submit to becoming unknown in the wild and return to your instincts.
          </p>
          <p>
          By recontextualizing sounds, Eric reminds the audience that all sound is music. Blurring the lines of what we hear and what we choose to listen to, he advocates for climate justice, a movement that has been muffled due to the urgency of the global pandemic. 
          </p>
          <p>
          The deeply textured pulse-infused listening wakes up our eco-consciousness, stimulating our sense of responsibility and encouraging a type of sonic bioregionalism. If we listen closer, we just might connect further. 
          </p>
          <p>
          <em>Höhn</em> immortalizes the natural world, puts a piece of the planet in your pocket with bass-bumping vigour. The paradox rings loudly, as we are reminded that harmony can exist when we decide to bridge the gap. 
          </p>
          <BtnGroup>
            <Btn buttonTitle={`Field Recordings`} isLink={true} linkTo={`/recordings`} />
            <Btn buttonTitle={`Home`} isLink={true} linkTo={`/`} />
          </BtnGroup>
        </FadeBlock>
		</div>
    
    </div>
      </Section>


      {/*<Section sectionName="Instagram" bgColor="var(--color-green)">
        <div>
          <InstagramFeed token="IGQVJVNERXUm9jOVd5OEotS042Y2tQT0FrUFY4V3JUZA1ljR05pYjlIV1BfaDZAYZAnlkX1VLWEYwMEtpaTFGM2wzVjVDalVjaG82dTR4OWh0STktOWV2R1F2ZA0xyZAFNvN2lzWjRUQzFpSkY1NldIX3JpawZDZD"  counter="12"/>
        </div>
    </Section> */}

      {/* <SplitBackground isReversed={true} style={{filter: 'hue-rotate(105deg) brightness(120%)', transform: 'scaleX(0.9)', transformOrigin: 'left'}} /> */}
    </Layout>
  )
}

export default AboutPage;
