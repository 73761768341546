import React from "react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import * as btnStyle from "../css/btn.module.scss"

const Btn = ({ linkTo, buttonTitle, isLink }) => {

	if (isLink === true) {
		return (
			<Link to={linkTo} className={`button font-sans lowercase linkButton ${btnStyle.btn} `}>{buttonTitle}</Link>
		)
	}

	return (
		<OutboundLink className={`button font-sans lowercase ${btnStyle.btn}`} href={linkTo} target="_blank" rel="noreferrer noopener">{buttonTitle}</OutboundLink>
	)

}

export const BtnGroup = ( { children, ...rest } ) => {

	return (

		<div className={`${btnStyle.btnGroup}`}>
			<div className={`flex flex-row lg:gap-4 md:gap-3 sm:gap-2 gap-1`} {...rest}>
				{children}
			</div>
		</div>
	)
}

export default Btn
