import React from 'react'
import Fade from 'react-reveal/Fade';

export default function FadeBlock(props) {

    const { direction = null, duration = 500, distance = "30px", children, ...rest } = props;

    return (
        <Fade 
        left={direction === 'left' ? true : false} 
        right={direction === 'right' ? true : false} 
        bottom={direction === 'bottom' ? true : false} 
        top={direction === 'top' ? true : false} 
        
        duration={duration} distance={distance}
        {...rest}
        >
            <div>
                {children}
            </div>
        </Fade>
    )
}